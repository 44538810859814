import '@/less';
import '@/lib/pt-standard';
import Vue from 'vue';
import createStore from '@/store';
import { showDialog } from '@/dialogs/dialog';
import { loadLanguage } from '@/utils/i18n';
import PtGlobalPlugin from '@/utils/plugins/pt-global-plugin';
import userInfoInit from './user-variables';
import '@/utils/pt-format';
import '@/custom-elements';

window.Vue = Vue;
window.showDialog = showDialog;

/** {Number} The milliseconds between pub-sub requests. Default 10 minutes */
window.SUBSCRIPTION_INTERVAL = 600000;
window.SUBSCRIPTION_INTERVALS = { DEFAULT: window.SUBSCRIPTION_INTERVAL };

/**
 * Handles initializing the Vue Application
 * @return {Promise} - The state of the async function
 */
const initApp = async function () {
	await userInfoInit();

	if (window.verifyUser) {
		window.verifyUser(60000);
	}

	window.USER_TIMEZONE = moment().tz(window.USER_TIMEZONE_NAME).utcOffset() * 60000;

	// Mobile wrapper specific logic - imported asynchronously so only the mobile wrapper loads it
	if (window.navigator && /MobileOV/.test(window.navigator.userAgent)) {
		import(/* webpackChunkName: "mobileWrapper.nopreload" */ '@/mobile-wrapper').then((module) => {
			module.default();
		});
	}

	createStore('/lineage', { 'X-Requested-With': 'XMLHttpRequest' }, true);

	window.i18n = await loadLanguage();

	Vue.use(PtGlobalPlugin);

	await import('@/routes/OneViewRouter');

	const module = await import('@/routes/main/components/pt-route-main-layout.vue');
	const Constructor = Vue.extend(module.default);
	// eslint-disable-next-line no-unused-vars
	const rootVueInstance = new Constructor({
		el: '#oneview-app',
		router: OneViewRouter,
		store: PT_STORE,
		propsData: {
			isProxied: !!window.ORIGINAL_USER
		}
	});

	// Maybe declare in the created hook
	OneViewRouter.onError((error) => {
		console.error(error);
		showDialog('PT_ALERT_DIALOG', {
			title: 'Error',
			content: error.message
		});
	});
};

$(document).ready(initApp);

// Remove old service workers that were causing slow-down
if (window.navigator && window.navigator.serviceWorker) {
	navigator.serviceWorker.getRegistrations().then((registrations) => {
		registrations.forEach((registration) => {
			registration.unregister();
		});
	});
}
