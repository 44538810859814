/**
 * The dialogs that are allowed to be navigated through the url
 * @type {Object}
 */
export default {
	PT_JOB_WORKFLOW_DIALOG: true,
	PT_EVENT_DIALOG: true,
	PT_ALARM_DETAILS_DIALOG: true,
	PT_PDF_VIEW_DIALOG: true,
	PT_DASHBOARD_FULLSCREEN_DIALOG: true,
	PT_FUEL_PURCHASE_UPLOAD_DIALOG: true,
	PT_READINGS_EXPORT_DIALOG: true,
	PT_ACTOR_TAG_VIEW_DIALOG: true,
	PT_SYSTEM_NOTIFICATIONS_DIALOG: true,
	PT_ASSET_IMPORT_DIALOG: true,
	PT_USER_IMPORT_DIALOG: true,
	PT_DRIVER_IMPORT_DIALOG: true,
	PT_JOB_IMPORT_DIALOG: true,
	PT_LANDMARK_IMPORT_DIALOG: true,
	PT_COLLECTION_IMPORT_DIALOG: true,
	PT_TAG_IMPORT_DIALOG: true,
	PT_DRIVER_LOG_IMPORT_DIALOG: true,
	PT_DEVICE_GLOBAL_SEARCH_RESULT_DIALOG: true,
	PT_WARRANTY_DETAILS_DIALOG: true,
	PT_INSPECTION_VIEW_DIALOG: true,
	PT_FORM_DETAILS_DIALOG: true,
	PT_DIGITAL_LIBRARY_UPLOAD_DIALOG: true,
	PT_DIGITAL_LIBRARY_VIEW_DIALOG: true,
	PT_NOTICE_MANAGEMENT_DIALOG: true,
	PT_ELD_RECORD_EDIT_DIALOG: true,
	PT_ELD_RECORD_VIEW_DIALOG: true,
	PT_VIDEO_EVENT_REQUEST_DIALOG: true,
	PT_JOB_CREATION_DIALOG: true,
	PT_JOB_RECORD_COMPLETED_DIALOG: true,
	PT_DASHBOARD_ADMIN_EDIT_DIALOG: true,
	PT_UNCERTIFIED_DAYS_DIALOG: true,
	PT_HOS_VIOLATIONS_DIALOG: true,
	PT_FORM_MANNER_VIOLATIONS_DIALOG: true,
	PT_DVIR_VIOLATIONS_DIALOG: true,
	PT_SCORECARD_CONFIG_DIALOG: true,
	PT_JOB_ROUTE_DETAILS_DIALOG: true,
	PT_DASHBOARD_SUBSCRIPTION_DIALOG: true,
	PT_DISPUTE_MANAGEMENT_DIALOG: true,
	PT_NOTICE_VIEW_DIALOG: true,
	PT_CONVERSATION_VIEW_DIALOG: true,
	PT_ROUTE_COMMUNICATIONS_LEGACY_DIALOG: true,
	PT_FLAG_LIST_DIALOG: true
};
