import * as DialogTracker from '@/dialogs/dialogTracker';

/**
 * Removes all currently opened dialogs
 */
export function removeAllDialogs() {
	DialogTracker.removeUpToDialog(-1);
}

/**
 * Shows the specified dialog.
 * If there are any dialogs opened above ths dialog, they will automatically be closed
 *
 * @param {String} entry - The entry from
 * @param {Object?} params - The dialog parameters to open with
 * @param {Boolean?} addToUrl - Adds the dialog to the url history
 */
export function showDialog(entry, params, addToUrl = true) {
	const existingIndex = DialogTracker.findExistingDialogIndex(entry, params);
	if (existingIndex > -1) {
		DialogTracker.removeUpToDialog(existingIndex);
	} else {
		DialogTracker.openDialog(entry, params);
	}

	if (addToUrl) {
		DialogTracker.addDialogToUrl(entry, params);
	}
}

/**
 * Shows a persistent dialogs that will not be closed by navigation/other dialogs
 *
 * @param {String} entry - The entry from
 * @param {*} params - The dialog parameters to open with
 */
export function showPersistentDialog(entry, params) {
	DialogTracker.openPersistentDialog(entry, params);
}
