import urlDialogs from '@/dialogs/urlDialogs';

/** {Object[]} The ordered list of opened dialogs, this is exported for ease of unit testing */
export const dialogs = [];

/** {String} The dialogs close event */
const CLOSE_EVENT = 'hide';

/** {Map<String, Boolean>} A simple map of dialogs that should only be shown once */
const SINGLE_DIALOGS = {
	PT_ALERT_DIALOG: true,
	PT_CONFIRMATION_DIALOG: true,
	PT_PROMPT_DIALOG: true
};

/**
 * Updates the parameters to be a string, if unable will return the original object
 *
 * @param {Object} params - The parameters to normalize
 * @returns {String|Object} The normalized parameters
 */
function normalizeParams(params) {
	try {
		return !params || typeof params === 'string' ? params : JSON.stringify(params);
	} catch (e) {
		// Invalid json, just return normally
		return params;
	}
}

/**
 * Finds the most current opened dialog that matches the entry + parameters
 *
 * @param {String} entry - The dialog entry name
 * @param {Object?} params - The parameters that were used to open the dialog
 * @return {number} The found index
 */
export function findExistingDialogIndex(entry, params) {
	entry = typeof entry === 'string' ? entry : entry.uniqueId;
	params = normalizeParams(params);

	return _.findLastIndex(
		dialogs,
		(dialog) => dialog.entry === entry
			&& (SINGLE_DIALOGS[entry] || _.isEqual(dialog.params, params))
	);
}

/**
 * Adds the dialog to the url
 *
 * @param {String?} entry - The dialog entry
 * @param {Object?} params - The dialog options
 */
export function addDialogToUrl(entry, params) {
	if (!window.OneViewRouter) {
		return;
	}

	const query = OneViewRouter.currentRoute.query || {};
	if (!urlDialogs[entry]) {
		if (query.d_entry && !dialogs.length) {
			OneViewRouter.push({
				...OneViewRouter.currentRoute,
				query: {
					...query,
					d_entry: undefined,
					d_params: undefined
				}
			});
		}
	} else {
		try {
			const paramString = !params || typeof params === 'string' ? params : JSON.stringify(params);
			if (query.d_entry !== entry || query.d_params !== paramString) {
				OneViewRouter.push({
					...OneViewRouter.currentRoute,
					query: {
						...query,
						d_entry: entry,
						d_params: paramString
					}
				});
			}
		} catch (e) {
			console.log('Unable to add dialog info to url', entry, params);
			console.error(e);
		}
	}
}

/**
 * Removes up to the specified index.
 * You can also pass in the entry and parameters to find the current index if it is unknown
 *
 * @param {number} index - The index of the desired top most dialog
 * @param {string?} entry - The entry name
 * @param {Object?} params - The parameters the dialog was opened with
 * @param {boolean?} includeSelf - Removes at the current index as well
 * @return {object} The dialog instance that remains
 */
export function removeUpToDialog(index, entry, params, includeSelf) {
	index = index === undefined ? findExistingDialogIndex(entry, params) : index;

	if (index === -1 && entry) {
		return undefined;
	}

	if (includeSelf) {
		index--;
	}

	for (let i = dialogs.length - 1; i > index; i--) {
		const vue = dialogs[i].vue;
		if (vue) {
			vue.$destroy();
			if (vue.$el) {
				document.body.removeChild(vue.$el);
			}
		}
	}
	if (index + 1 < dialogs.length) {
		dialogs.length = index + 1;
	}

	return dialogs[index];
}

/**
 * Uses VUE_LOADER to get the correct vue constructor
 *
 * @param {String} entry - The dialog's entry
 * @return {Promise} Resolved with the ready to use constructor
 */
function getConstructor(entry) {
	return import('@/VUE_LOADER').then((module) => module.default(entry));
}

/**
 * Exits any fullscreen element so the dialogs can be shown
 *
 * @returns {Promise} Resolved when the fullscreen element is gone
 */
function exitFullscreen() {
	if (document.fullscreenElement) {
		document.exitFullscreen();
		return new Promise((resolve) => {
			setTimeout(resolve, 16);
		});
	}

	const manualFullScreen = document.querySelector('.pt-geo-map-fullscreen .manual-fullscreen-map');
	if (manualFullScreen) {
		manualFullScreen.dispatchEvent(new Event('click'));
	}
	return Promise.resolve();
}

/**
 * Opens the desired dialog with the specified parameters
 *
 * @param {String} entry - The dialog's entry (e.g. PT_MY_DIALOG)
 * @param {Object?} params - The expected dialog parameters
 * @return {object} the dialog instance param
 */
export async function openDialog(entry, params) {
	const dialog = {
		entry,
		params: normalizeParams(params)
	};
	dialogs.push(dialog);
	const element = document.createElement('DIV');
	document.body.appendChild(element);

	const Constructor = await getConstructor(entry);

	// Dialog was removed before the constructor was resolved
	if (dialogs.indexOf(dialog) === -1) {
		return;
	}
	await exitFullscreen();

	dialog.vue = new Constructor({
		store: window.PT_STORE,
		router: window.OneViewRouter,
		el: element,
		propsData: params || {}
	});

	dialog.vue.$on(CLOSE_EVENT, () => {
		const last = removeUpToDialog(undefined, entry, dialog.params, true);
		if (last) {
			addDialogToUrl(last.entry, last.params);
		} else {
			addDialogToUrl();
		}
	});

	if (!SINGLE_DIALOGS[entry] && window.gtag) {
		gtag('event', 'page_view', {
			event_name: 'page_view',
			event: 'page_view',
			page_location: entry,
			page_title: entry
		});
	}
}

/**
 * Opens a persistent dialog
 *
 * @param {String} entry - The dialog's entry (e.g. PT_MY_DIALOG)
 * @param {Object?} params - The expected dialog parameters
 * @return {Promise<void>} The state of the constructor
 */
export async function openPersistentDialog(entry, params) {
	const element = document.createElement('DIV');
	document.body.appendChild(element);

	const Constructor = await getConstructor(entry);

	const vue = new Constructor({
		store: window.PT_STORE,
		router: window.OneViewRouter,
		el: element,
		propsData: params
	});

	vue.$on(CLOSE_EVENT, () => {
		vue.$destroy();
		if (vue.$el) {
			document.body.removeChild(vue.$el);
		}
	});
}
